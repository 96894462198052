<template>
  <v-app>
    <div class="ma-5" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate/>
      <br/>
      お客様の情報を確認中です。<br/>
      処理が完了するまでお待ちください。
    </div>


    <div class="ma-5" v-if="loading === false && clinic != undefined">
      <h2 text-center >患者様登録</h2>
      患者様番号を画像認識で登録します。お手元に「{{clinic.name}}」診察券のご準備をおねがいします。<br/>
       <v-img class="ma-5"
          height="200"
          :src="image_url"
          aspect-ratio="1.0"
          contain
        ></v-img>
      <br/>
      診察券のご準備ができましたら、「カメラ」ボタンを押して撮影を開始します。
      次画面で、カメラへのアクセスを確認する表示が出ますので、カメラへのアクセスを許可してください。<br/>
      <p
        class="text-center ">
      <v-btn
        class="ma-5 text-center "
        fab
        x-large
        dark
        color="indigo"
        v-on:click="start_camera()"
      >
        <v-icon>mdi-camera</v-icon>
      </v-btn>
      </p>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'PatientPri',

  data(){
    return {
      loading: true,
      image_url: ""
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    user_id() {
      return this.$store.getters.user.uid
    },
    clinic(){
      if(this.id === ""){
        return undefined
      }else{
        return this.$store.state.clinicModule.data[this.id]
      }
    },
    user() {
      return this.$store.state.userModule.data[this.user_id]
    },
  },

  created: async function () {
    try {
      await this.fetchClinics()
    } catch (e) {
      this.error = true
      console.log(e)
    } finally {
      this.loading = false
    }
    this.loading = false
    try {
      await this.fetchUsers()
    } catch(e) {
      this.error = true
      console.log(e)
    } finally {
      this.loading = false
    }
    this.loading = false
  },
  methods: {
    fetchUsers: async function () {
      await this.$store.dispatch('userModule/fetchById', this.$store.getters.user.uid)
    },
    fetchClinics: async function () {
      await this.$store.commit('clinicModule/RESET_VUEX_EASY_FIRESTORE_STATE')
      try {
        await this.$store.dispatch('clinicModule/fetchById', this.id)
        if(this.clinic.is_card_scan == false){
          if(this.clinic.is_form == true){
              this.$router.push({ path: '/patient_form_regster/' + this.id })
          }else{
            this.$router.push({ path: '/patient_regster/' + this.id })
          }
        }
      } catch(e) {
        this.error = true
        this.$router.push({ name: "error", params: { message:  "表示できません。" } })
      } finally {
        this.loading = false
      }
      this.image_url =  "/images/cards/" + this.clinic.id + ".png"
    },
    start_camera () {
        this.$router.push({ path: '/patient_regster/' + this.id })
    },

  }
};
</script>

<style>

</style>
